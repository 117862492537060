import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"height":"370","max-width":"280"}},[_c(VCardText,{staticClass:"pt-10 px-6 pb-6 fill-height"},[_c(VRow,{staticClass:"mx-0 fill-height",attrs:{"align-content":"space-between","justify":"center"}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VImg,{staticClass:"mx-auto mb-6",attrs:{"src":("" + _vm.publicPath + _vm.image),"height":"120","width":"120"}}),(_vm.availableServiceId === 45)?[_c('h2',{staticClass:"title text-center black--text mb-5"},[_vm._v(" "+_vm._s(_vm.$t('services.asuw2'))+" ")])]:(_vm.availableServiceId === 43)?[_c('h2',{staticClass:"title text-center black--text mb-5"},[_vm._v(" "+_vm._s(_vm.$t('services.vodaiw'))+" ")])]:[_c('h2',{staticClass:"title text-center black--text mb-5"},[_vm._v(" "+_vm._s(_vm.$t(("" + _vm.title)))+" ")])]],2),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"primary",on:{"click":function($event){return _vm.newOrderFormPage(_vm.path)}}},[_vm._v(" "+_vm._s(_vm.$t('button.select'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }