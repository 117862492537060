<template>
  <v-card height="370" max-width="280">
    <v-card-text class="pt-10 px-6 pb-6 fill-height">
      <v-row class="mx-0 fill-height" align-content="space-between" justify="center">
        <v-col cols="12" class="pa-0">
          <v-img :src="`${publicPath}${image}`" height="120" width="120" class="mx-auto mb-6"></v-img>
          <template v-if="availableServiceId === 45">
            <h2 class="title text-center black--text mb-5">
              {{ $t('services.asuw2') }}
            </h2>
          </template>
          <template v-else-if="availableServiceId === 43">
            <h2 class="title text-center black--text mb-5">
              {{ $t('services.vodaiw') }}
            </h2>
          </template>
          <template v-else>
            <h2 class="title text-center black--text mb-5">
            {{ $t(`${title}`) }}
            </h2>
          </template>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-btn class="primary" @click="newOrderFormPage(path)">
            {{ $t('button.select') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ServiceCard',
  props: {
    image: String,
    title: String,
    path: {},
    isExternalLink: {
      type: Boolean,
      default: false,
    },
    availableServiceId: {
      type: Number,
      default: 987654321,
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    newOrderFormPage(path) {
      if(this.isExternalLink) {
        window.open(path.url, '_blank');
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>

</style>
