<template>
  <v-container>
    <v-row justify="center">
      <template v-for="(item, index) in listThisItems">
        <v-col cols="12" sm="auto" :key="index"
               v-show="($store.state.authModule.isAuthenticated && $store.state.authModule.user) ? item.roles.includes($store.state.authModule.user.profile.customerType) : true">
          <div v-show="item.showCard">
            <service-card :image="item.image"
                          :title="item.title ? item.title : item.warrantyTypeName"
                          :path="useAltPath ? item.altPath : item.path" :is-external-link="item.isExternal"></service-card>
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import ServiceCard from "./ServiceCard.vue";

export default {
  name: 'ListServices',
  props: {
    listThisItems: Array,
    useAltPath: Boolean,
  },
  components: {
    ServiceCard,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
