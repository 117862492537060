<template>
  <list-services :list-this-items="listThisItems"></list-services>
</template>

<script>
import ListServices from "../../components/services/ListServices.vue";

export default {
  name: 'InnerNewOrderServiceSelect',
  props: {
    //
  },
  components: {
    ListServices
  },
  data() {
    return {
      listThisItems: this.$store.state.services.baseServices,
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
